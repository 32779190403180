import React, {useState} from 'react';
import { TextField, Button, Stack, FormGroup } from '@mui/material';
import { Link,useParams,useNavigate, useLoaderData } from "react-router-dom";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
 
const UserDetails = (props) => {
    const {userDetails, versions} = useLoaderData();

    let {userId}  = useParams();
    const navigate = useNavigate();

    const [email, setEmail] = useState(userDetails != null ? userDetails.email : '');
    const [status, setStatus] = useState(userDetails != null ? userDetails.status.id : '1');
    const [password, setPassword] = useState('');
    let initialTypeViewer = false;
    let initialTypeAdministrator = false;
    userDetails.types.forEach(type => {
        if(type.id === 1){
            initialTypeViewer = true;
        }
        if(type.id === 2){
            initialTypeAdministrator = true;
        }
    })
    const [typeViewer, setTypeViewer] = useState(initialTypeViewer);
    const [typeAdministrator, setTypeAdministrator] = useState(initialTypeAdministrator);
    
    

    let versionsSelected = [];
    userDetails.versions.forEach(version => {
        versionsSelected.push(version);
    });
    const [selected, setSelected] = useState(versionsSelected);

    let backlink = "/";
    if(userDetails.status.id === 1){
        backlink = "/pending-users";
    }else if(userDetails.status.id === 2){
        backlink = "/approved-users";
    }else{
        backlink = "/rejected-users";
    }

    function handleSubmit(event) {
        event.preventDefault();
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
            const formData = new FormData();

            formData.append("email", email);
            formData.append("status", status);
            if(password !== ''){
                formData.append("password", password);
            }
            let types = [];
            if(typeViewer){
                types.push(1);
            }
            if(typeAdministrator){
                types.push(2);
            }
            formData.append("types", JSON.stringify(types));
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);
            const url = `${
                    process.env.REACT_APP_API_BASE
                    }/save-user/${userId}`;
        
            fetch(url, {
                method: "POST",
                body: formData,
                headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              }) 
            .then(json => {
                if(status === 1){
                    navigate("/pending-users");
                }else if(status === 2){
                    navigate("/approved-users");
                }else{
                    navigate("/rejected-users");
                }
                }) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  navigate("/login");
                }
              });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }

    function handleSelect (id){
        if (!selected.includes(id)) {
            setSelected([...selected, id]);
            return;
        }else{
            let newSelected = selected.filter(el => {
                if(el === id){
                    return false;
                }
                return true;
            })
            setSelected(newSelected);
            return;
        }
    };


    function selectAll (){
        if(selected.length === versions.length){
            setSelected([]);
        }else{
            setSelected(versions.map(version => version.id));
        }
    }

    function sendUserVersions(event) {
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
            const formData = new FormData();

            formData.append("versions", JSON.stringify(selected));
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);
            const url = `${
                    process.env.REACT_APP_API_BASE
                    }/user-associate-versions/${userId}`;
        
            fetch(url, {
                method: "POST",
                body: formData,
                headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              })
            .then(json => {
                navigate(backlink);
                }) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  navigate("/login");
                }
              });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }

    return (
        <React.Fragment>
            <h2>User Details</h2>
            <form autoComplete="off" onSubmit={handleSubmit} action={<Link to="/login" />}>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        fullWidth
                        required
                    />
                </Stack>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <TextField
                        type="password"
                        variant='outlined'
                        color='primary'
                        label="Password"
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        autoComplete="off"
                    />
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="staus-label">Status</InputLabel>
                            <Select
                            labelId="staus-label"
                            id="status-select"
                            value={status}
                            label="Status"
                            onChange={e => {setStatus(e.target.value)}}
                            >
                            <MenuItem value={1}>Pending</MenuItem>
                            <MenuItem value={2}>Approved</MenuItem>
                            <MenuItem value={3}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox style={{padding:"2px"}} onChange={()=> setTypeViewer(!typeViewer)} checked={typeViewer} />} label="Viewer" />
                        <FormControlLabel control={<Checkbox style={{padding:"2px"}} onChange={()=> setTypeAdministrator(!typeAdministrator)} checked={typeAdministrator} />} label="Administrator" />
                    </FormGroup>
                </Stack>
                <Stack spacing={1} direction="row">
                    <Button variant="outlined" color="secondary"><Link to={backlink}>Back</Link></Button>
                    <Button variant="outlined" color="primary" type="submit">Save</Button>
                </Stack>
            </form>


            <h2>Versions Associated</h2>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox
                                color="primary"
                                checked={selected.length === versions.length}
                                onChange={selectAll}
                                inputProps={{
                                'aria-label': 'select all desserts',
                                }}
                            />
                        </TableCell>
                        <TableCell>Brand</TableCell>
                        <TableCell>Fleet</TableCell>
                        <TableCell>Version</TableCell>
                        <TableCell>Subversion</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {versions.map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell padding="checkbox" component="th" scope="row" align="center">
                                <Checkbox
                                    color="primary"
                                    checked={selected.includes(row.id)}
                                    onChange={(e) => {handleSelect(row.id)}}
                                    inputProps={{
                                    'aria-label': 'select row',
                                    }}
                                />
                            </TableCell>
                            <TableCell>{row.brand_name}</TableCell>
                            <TableCell>{row.fleet_name}</TableCell>
                            <TableCell>{row.version_name}</TableCell>
                            <TableCell>{row.version_subversion}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack sx={{ marginTop: 4 }} spacing={1} direction="row">
                <Button variant="outlined" color="secondary"><Link to={backlink}>Back</Link></Button>
                <Button onClick={sendUserVersions} variant="outlined" color="primary" type="submit">Save</Button>
            </Stack>
        </React.Fragment>
    )
}
 
export default UserDetails;