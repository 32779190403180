import React, {useState} from 'react';
import { TextField, Button, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import ButtonGroup from '@mui/material/ButtonGroup';
import ClearIcon from '@mui/icons-material/Clear';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Link, useParams, useNavigate, useLoaderData } from "react-router-dom";
import Media from "./Media"
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
 
const NewEditVersion = () => {
    const {version, users} = useLoaderData();

    const [loadingVersion, setLoadingVersion] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);

    const [name, setName] = useState(version != null ? version.name : '');
    const [subversion, setSubversion] = useState(version != null && version.subversion != null ? version.subversion : '');
    const [silhouetteFile, setSilhouetteFile] = useState(version != null ? version.media.version_list["it-IT"][0].original_filename : '');
    const [mainFile, setMainFile] = useState(version != null ? version.media.version_main["it-IT"][0].original_filename : '');
    const [technicalFile, setTechnicalFile] = useState(version != null ? version.media.version_details["it-IT"][0].original_filename : '');
    const [exteriorFile, setExteriorFile] = useState(version != null && version.media.exterior_design_main != null ? version.media.exterior_design_main["it-IT"][0].original_filename : '');
    const [interiorFile, setInteriorFile] = useState(version != null && version.media.interior_design_main != null ? version.media.interior_design_main["it-IT"][0].original_filename : '');
    const navigate = useNavigate();

    let usersSelected = [];
    if(version){
        version.users.forEach(version => {
            usersSelected.push(version);
        });
    }
    const [selected, setSelected] = useState(usersSelected);

    let { brandId, fleetId, versionId } = useParams();
 
    function handleSubmit(event) {
        event.preventDefault();
        
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
            setLoadingVersion(true);
            if(versionId == null && silhouetteFile === ''){
                return null;
            }
            if(versionId == null && mainFile === ''){
                return null;
            }
            if(versionId == null && technicalFile === ''){
                return null;
            }

            const formData = new FormData();

            formData.append("name", name);
            formData.append("subversion", subversion);
            if(silhouetteFile !== '' && typeof silhouetteFile === "object"){
                formData.append("version_list", silhouetteFile);
            }
            if(mainFile !== '' && typeof mainFile === "object"){
                formData.append("version_main", mainFile);
            }
            if(technicalFile !== '' && typeof technicalFile === "object"){
                formData.append("version_details", technicalFile);
            }
            if(exteriorFile === '' || (exteriorFile !== '' && typeof exteriorFile === "object")){
                formData.append("version_exterior_main", exteriorFile);
            }
            if(interiorFile === '' || (interiorFile !== '' && typeof interiorFile === "object")){
                formData.append("version_interior_main", interiorFile);
            }
            formData.append("fleet", fleetId);
            formData.append("language", 'it-IT');
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);

            let url = null;
            if(fleetId != null){
                url = `${
                    process.env.REACT_APP_API_BASE
                    }/save-version/${versionId}`;
            }else{
                url = `${
                    process.env.REACT_APP_API_BASE
                    }/save-version`;
            }
        
            fetch(url, {
                method: "POST",
                body: formData,
                headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              }) 
            .then(json => {setLoadingVersion(false); navigate("/brand/"+brandId)}) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  navigate("/login");
                }
              });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }

    function deleteSilhouetteFile(){
        setSilhouetteFile('');
    }

    function handleSilhouetteFile(e){
        setSilhouetteFile(e.target.files[0]);
    }

    function deleteMainFile(){
        setMainFile('');
    }

    function handleMainFile(e){
        setMainFile(e.target.files[0]);
    }

    function deleteTechnicalFile(){
        setTechnicalFile('');
    }

    function handleTechnicalFile(e){
        setTechnicalFile(e.target.files[0]);
    }

    function deleteExteriorFile(){
        setExteriorFile('');
    }

    function handleExteriorFile(e){
        setExteriorFile(e.target.files[0]);
    }

    function deleteInteriorFile(){
        setInteriorFile('');
    }

    function handleInteriorFile(e){
        setInteriorFile(e.target.files[0]);
    }

    function handleSelect (id){
        if (!selected.includes(id)) {
            setSelected([...selected, id]);
            return;
        }else{
            let newSelected = selected.filter(el => {
                if(el === id){
                    return false;
                }
                return true;
            })
            setSelected(newSelected);
            return;
        }
    };


    function selectAll (){
        if(selected.length === users.length){
            setSelected([]);
        }else{
            setSelected(users.map(user => user.id));
        }
    }

    function sendVersionUsers(event) {
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
            setLoadingUsers(true);
            const formData = new FormData();

            formData.append("users", JSON.stringify(selected));

            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);
            const url = `${
                    process.env.REACT_APP_API_BASE
                    }/version-associate-users/${versionId}`;
        
            fetch(url, {
                method: "POST",
                body: formData,
                headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              }) 
            .then(json => {
                setLoadingUsers(false);
                }) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  navigate("/login");
                }
              });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }
 
    return (
        <React.Fragment>
            <h2>Version</h2>
            <form onSubmit={handleSubmit} action={<Link to="/login" />}>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Name"
                        onChange={e => setName(e.target.value)}
                        value={name}
                        fullWidth
                        required
                    />
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Sub Version"
                        onChange={e => setSubversion(e.target.value)}
                        value={subversion}
                        fullWidth
                    />
                </Stack>

                <TextField
                    variant="outlined"
                    color='primary'       
                    type="text"
                    fullWidth
                    required
                    label="Silhouette"
                    sx={{mb: 4}}
                    disabled
                    value={silhouetteFile ? (typeof silhouetteFile === "string" ? silhouetteFile : silhouetteFile.name) : "Select Image"}
                    InputProps={{
                        endAdornment: (
                            <ButtonGroup aria-label="outlined primary button group"><IconButton component="label">
                            <FileUploadOutlined />
                            <input
                            styles={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleSilhouetteFile}
                            name="silhouette"
                            />
                        </IconButton>
                        <IconButton component="label" onClick={deleteSilhouetteFile}>
                            <ClearIcon />
                        </IconButton>
                        </ButtonGroup>
                        ),
                    }}
                    />
                <TextField
                    variant="outlined"
                    color='primary'       
                    type="text"
                    fullWidth
                    required
                    label="Main Picture"
                    sx={{mb: 4}}
                    disabled
                    value={mainFile ? (typeof mainFile === "string" ? mainFile : mainFile.name) : "Select Image"}
                    InputProps={{
                        endAdornment: (
                            <ButtonGroup aria-label="outlined primary button group"><IconButton component="label">
                            <FileUploadOutlined />
                            <input
                            styles={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleMainFile}
                            name="main-file"
                            />
                        </IconButton>
                        <IconButton component="label" onClick={deleteMainFile}>
                            <ClearIcon />
                        </IconButton>
                        </ButtonGroup>
                        ),
                    }}
                    />

                <TextField
                    variant="outlined"
                    color='primary'       
                    type="text"
                    fullWidth
                    required
                    label="Technical Picture"
                    sx={{mb: 4}}
                    disabled
                    value={technicalFile ? (typeof technicalFile === "string" ? technicalFile : technicalFile.name) : "Select Image"}
                    InputProps={{
                        endAdornment: (
                            <ButtonGroup aria-label="outlined primary button group"><IconButton component="label">
                            <FileUploadOutlined />
                            <input
                            styles={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleTechnicalFile}
                            name="technical-file"
                            />
                        </IconButton>
                        <IconButton component="label" onClick={deleteTechnicalFile}>
                            <ClearIcon />
                        </IconButton>
                        </ButtonGroup>
                        ),
                    }}
                    />

                <TextField
                    variant="outlined"
                    color='primary'       
                    type="text"
                    fullWidth
                    label="Exterior Main Picture"
                    sx={{mb: 4}}
                    disabled
                    value={exteriorFile ? (typeof exteriorFile === "string" ? exteriorFile : exteriorFile.name) : "Select Image"}
                    InputProps={{
                        endAdornment: (
                            <ButtonGroup aria-label="outlined primary button group"><IconButton component="label">
                            <FileUploadOutlined />
                            <input
                            styles={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleExteriorFile}
                            name="exterior-file"
                            />
                        </IconButton>
                        <IconButton component="label" onClick={deleteExteriorFile}>
                            <ClearIcon />
                        </IconButton>
                        </ButtonGroup>
                        ),
                    }}
                    />

                <TextField
                    variant="outlined"
                    color='primary'       
                    type="text"
                    fullWidth
                    label="Interior Main Picture"
                    sx={{mb: 4}}
                    disabled
                    value={interiorFile ? (typeof interiorFile === "string" ? interiorFile : interiorFile.name) : "Select Image"}
                    InputProps={{
                        endAdornment: (
                            <ButtonGroup aria-label="outlined primary button group"><IconButton component="label">
                            <FileUploadOutlined />
                            <input
                            styles={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleInteriorFile}
                            name="interior-file"
                            />
                        </IconButton>
                        <IconButton component="label" onClick={deleteInteriorFile}>
                            <ClearIcon />
                        </IconButton>
                        </ButtonGroup>
                        ),
                    }}
                    />
                <Stack spacing={1} direction="row">
                    <Button variant="outlined" color="secondary"><Link to={"/brand/"+brandId}>Back</Link></Button>
                    <LoadingButton
                        type="submit"
                        loading = {loadingVersion}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                        variant="outlined"
                    >
                        Save
                    </LoadingButton>
                    {/*<Button variant="outlined" color="primary" type="submit">Save</Button>*/}
                </Stack>
            </form>

            {version &&
                <>
                    {version.media.exterior_design_main  !=  null ? <Media category="5" version={versionId} media={version.media.exterior_design != null ? version.media.exterior_design["it-IT"] : []} /> : <div><h2>Exterior Design</h2><Typography>Insert "Exterior Main Picture" to Insert Media</Typography></div>}
                    {version.media.interior_design_main  !=  null ? <Media category="7" version={versionId} media={version.media.interior_design != null ? version.media.interior_design["it-IT"] : []} /> : <div><h2>Interior Design</h2><Typography>Insert "Interior Main Picture" to Insert Media</Typography></div>}
                    <Media category="8" version={versionId} media={version.media.general_plan != null ? version.media.general_plan["it-IT"] : []} />
                    <Media category="9" version={versionId} media={version.media.data_sheet != null ? version.media.data_sheet["it-IT"] : []} />
                    <Media category="10" version={versionId} media={version.media.brochure != null ? version.media.brochure["it-IT"] : []} />
                    <Media category="11" version={versionId} media={version.media.video != null ? version.media.video["it-IT"] : []} />
                </>
            }




            {version &&
                <>
                    <h2>Users Associated</h2>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        color="primary"
                                        checked={selected.length === users.length}
                                        onChange={selectAll}
                                        inputProps={{
                                        'aria-label': 'select all desserts',
                                        }}
                                    />
                                </TableCell>
                                <TableCell>E-mail</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {users.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell padding="checkbox" component="th" scope="row" align="center">
                                        <Checkbox
                                            color="primary"
                                            checked={selected.includes(row.id)}
                                            onChange={(e) => {handleSelect(row.id)}}
                                            inputProps={{
                                            'aria-label': 'select row',
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{row.email}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Stack sx={{ marginTop: 4 }} spacing={1} direction="row">
                        {/*<Button variant="outlined" color="secondary"><Link to={"/brand/"+brandId}>Back</Link></Button>*/}
                        <LoadingButton
                            onClick = {sendVersionUsers}
                            loading = {loadingUsers}
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Save
                        </LoadingButton>
                        {/*<Button onClick={sendVersionUsers} variant="outlined" color="primary" type="submit">Save</Button>*/}
                    </Stack>
                </>
            }
     
        </React.Fragment>
    )
}
 
export default NewEditVersion;