import { useLoaderData } from "react-router-dom";
import Media from "./Media"

function CompanyVideos(){
    const videos = useLoaderData();
    
    return (
        <Media category="12" media={videos != null && videos["it-IT"].length > 0 ? videos["it-IT"] : []} />
    );
}

export default CompanyVideos;