import React, { useState } from 'react';
//import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom"
import ConfirmDialog from './ConfirmDialog';

export default function Brand() {
  const brand = useLoaderData();
  const [open, setOpen] = React.useState(false);
  let { brandId } = useParams();
  const navigate = useNavigate();
  const [fleetToDelete, setFleetToDelete] = useState(null);
  const [versionToDelete, setVersionToDelete] = useState(null);


  function deleteFleet(id){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
        setFleetToDelete(null);

        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+login.token);
        const url = `${
          process.env.REACT_APP_API_BASE
          }/delete-fleet/${id}`;

        fetch(url, {
          method: "GET",
          headers: myHeaders
        })
        .then(response => {
          if(response.status!==200)
          {
              throw new Error(response.status)
          }
          return response.json();
        }) 
        .then(json => {navigate("/brand/"+brandId)}) 
        .catch(err => {
          if(parseInt(err.message) === 401){
            localStorage.clear();
            navigate("/login");
          }
        });
      }else{
          localStorage.clear();
          navigate("/login");
    }
}

  function deleteVersion(id){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+login.token);
        const url = `${
          process.env.REACT_APP_API_BASE
          }/delete-version/${id}`;

        fetch(url, {
          method: "GET",
          headers: myHeaders
        })
        .then(response => {
          if(response.status!==200)
          {
              throw new Error(response.status)
          }
          return response.json();
        }) 
        .then(json => {navigate("/brand/"+brandId)}) 
        .catch(err => {
          if(parseInt(err.message) === 401){
            localStorage.clear();
            navigate("/login");
          }
        });
    }else{
        localStorage.clear();
        navigate("/login");
  }
}

  let deleteFleetMessage = null;
  if(fleetToDelete != null){
    brand.fleets.forEach(fleet => {
      if(fleet.id === fleetToDelete){
        deleteFleetMessage = 'Are you sure to delete "'+fleet.name+'"?';
      }
    });
  }

  let deleteVersionMessage = null;
  if(versionToDelete != null){
    brand.fleets.forEach(fleet => {
      fleet.versions.forEach(version => {
        if(version.id === versionToDelete){
          deleteVersionMessage = 'Are you sure to delete "'+version.name+'"?';
        }
      });
    });
  }

  
  return (
    <Box>
        <ConfirmDialog message={deleteFleetMessage} confirm={() => deleteFleet(fleetToDelete)} disagree={()=>{setFleetToDelete(null)}} />
        <ConfirmDialog message={deleteVersionMessage} confirm={() => deleteVersion(versionToDelete)} disagree={()=>{setVersionToDelete(null)}} />
        <Typography variant="h3">Brand: {brand.name}
          <Link to="new-fleet">
            <IconButton aria-label="add">
                <AddIcon />
            </IconButton>
          </Link>
        </Typography> 
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
              <TableHead>
              <TableRow>
                  <TableCell />
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Order</TableCell>
                  <TableCell align="right">Action</TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {brand.fleets != null && brand.fleets.map((row) => (
                <React.Fragment key={row.id}>
                  <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {if(row.id === open){setOpen(null)}else{setOpen(row.id)}}}
                      >
                        {open === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.order}</TableCell>
                    <TableCell align="right">
                        <IconButton aria-label="add">
                          <Link to={"/brand/"+brandId+"/fleet/"+row.id+"/new-version"}>
                            <AddIcon />
                          </Link>
                        </IconButton>
                        <IconButton aria-label="edit">
                          <Link to={"/brand/"+brandId+"/edit-fleet/"+row.id}>
                            <EditIcon />
                          </Link>
                        </IconButton>
                        <IconButton onClick={()=>{setFleetToDelete(row.id)}} aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={open === row.id} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Versions
                          </Typography>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>SubVersion</TableCell>
                                <TableCell align="right">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.versions.map((version) => (
                                <TableRow key={version.id}>
                                  <TableCell component="th" scope="row">
                                    {version.name}
                                  </TableCell>
                                  <TableCell>{version.subversion}</TableCell>
                                  <TableCell align="right">
                                    <IconButton aria-label="edit">
                                      <Link to={"/brand/"+brandId+"/fleet/"+row.id+"/edit-version/"+version.id}>
                                        <EditIcon />
                                      </Link>
                                    </IconButton>
                                    <IconButton onClick={()=>{setVersionToDelete(version.id)}} aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
              </TableBody>
          </Table>
        </TableContainer>
    </Box>
  );
}