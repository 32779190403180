import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Link } from "react-router-dom";

export const companyListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Company
    </ListSubheader>
    <Link to="/company-videos">
      <ListItemButton>
        <ListItemIcon>
          <PlayCircleFilledIcon />
        </ListItemIcon>
        <ListItemText primary="Videos" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

export const brandsListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Brands
    </ListSubheader>
    <Link to="/brand/1">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Picchiotti" />
      </ListItemButton>
    </Link>
    <Link to="/brand/2">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Admiral" />
      </ListItemButton>
    </Link>
    <Link to="/brand/3">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Perini Navi" />
      </ListItemButton>
    </Link>
    <Link to="/brand/4">
      <ListItemButton>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Tecnomar" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);

function logout(){

  let login = JSON.parse(localStorage.getItem("login"));
  localStorage.clear();
  if(login){
      
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+login.token);
      const url = `${
        process.env.REACT_APP_API_BASE
        }/logout`;

      fetch(url, {
        method: "GET",
        headers: myHeaders
      })
      .then(response => {
        if(response.status!==200)
        {
            throw new Error(response.status)
        }
        return response.json();
      }) 
      .then(json => {}) 
      .catch(err => {
        
      });
  }
}

export const usersListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Users
    </ListSubheader>
    <Link to="/pending-users">
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Pending" />
      </ListItemButton>
    </Link>
    <Link to="/approved-users">
      <ListItemButton>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Approved" />
      </ListItemButton>
    </Link>
    <Link to="/rejected-users">
      <ListItemButton>
        <ListItemIcon>
          <RemoveCircleIcon />
        </ListItemIcon>
        <ListItemText primary="Rejected" />
      </ListItemButton>
    </Link>
    <Link to="/login" onClick={(  )=>{
      logout();
    }}>
      <ListItemButton>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </Link>
  </React.Fragment>
);