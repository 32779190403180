import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  redirect
} from "react-router-dom";
import SignIn from './SignIn';
import Dashboard from './Dashboard';
import Brand from './Brand';
import Welcome from './Welcome';
import NewEditFleet from './NewEditFleet';
import NewEditVersion from './NewEditVersion';
import CompanyVideos from './CompanyVideos';
import Users from "./Users";
import UserDetails from "./UserDetails";

function App() {
  let brand = null;

  function loadFleet({params}){
    let fleetSelected = null;
    if(brand.fleets != null){
      brand.fleets.forEach(fleet => {
        if(fleet.id === parseInt(params.fleetId)){
          fleetSelected = fleet;
        }
      });
    }
    return fleetSelected;
  }

  async function loadVersion({params}){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
      for (let i = 0; brand.fleets.length; i++){
        if(brand.fleets[i].id === parseInt(params.fleetId)){
          for (let z = 0; brand.fleets[i].versions.length; z++){
            if(brand.fleets[i].versions[z].id === parseInt(params.versionId)){
              let version = brand.fleets[i].versions[z];
              let myHeaders = new Headers();
              myHeaders.append("Authorization", "Bearer "+login.token);
              const url = `${
                process.env.REACT_APP_API_BASE
                }/approved-users`;
          
              return fetch(url, {
                method: "GET",
                headers: myHeaders
              })
              .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              }) 
              .then(users => {return {version, users}}) 
              .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  return redirect("/login")
                }
                return null;
              });
            }
          }
        }
      }
    }
    return redirect("/login");
  }

  async function loadBrand( { params } ){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+login.token);
      const url = `${
        process.env.REACT_APP_API_BASE
        }/fleets/${params.brandId}`;

      return fetch(url, {
        method: "GET",
        headers: myHeaders
      })
      .then(response => {
        if(response.status!==200)
        {
            throw new Error(response.status)
        }
        return response.json();
      }) 
      .then(json => {brand = json; return json;}) 
      .catch(err => {
        if(parseInt(err.message) === 401){
          localStorage.clear();
          return redirect("/login")
        }
        return null;
      });
    }
    return redirect("/login");
  }

  async function loadCompanyVideos( ){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+login.token);
      const url = `${
        process.env.REACT_APP_API_BASE
        }/company-videos`;

      return fetch(url, {
        method: "GET",
        headers: myHeaders
      })
      .then(response => {
        if(response.status!==200)
        {
            throw new Error(response.status)
        }
        return response.json();
      }) 
      .then(json => {return json;}) 
      .catch(err => {
        if(parseInt(err.message) === 401){
          localStorage.clear();
          return redirect("/login")
        }
        return null;
      });
    }
    return redirect("/login")
  }

  async function loadPendingUsers( { params } ){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+login.token);
      const url = `${
        process.env.REACT_APP_API_BASE
        }/pending-users`;

      return fetch(url, {
        method: "GET",
        headers: myHeaders
      })
      .then(response => {
        if(response.status!==200)
        {
            throw new Error(response.status)
        }
        return response.json();
      }) 
      .then(json => {return json;}) 
      .catch(err => {
        if(parseInt(err.message) === 401){
          localStorage.clear();
          return redirect("/login")
        }
        return null;
      });
    }
    return redirect("/login")
  }

  async function loadApprovedUsers( { params } ){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+login.token);
      const url = `${
        process.env.REACT_APP_API_BASE
        }/approved-users`;

      return fetch(url, {
        method: "GET",
        headers: myHeaders
      })
      .then(response => {
        if(response.status!==200)
        {
            throw new Error(response.status)
        }
        return response.json();
      }) 
      .then(json => {return json;}) 
      .catch(err => {
        if(parseInt(err.message) === 401){
          localStorage.clear();
          return redirect("/login")
        }
        return null;
      });
    }
    return redirect("/login")
  }

  async function loadRejectedUsers( { params } ){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+login.token);
      const url = `${
        process.env.REACT_APP_API_BASE
        }/rejected-users`;

      return fetch(url, {
        method: "GET",
        headers: myHeaders
      })
      .then(response => {
        if(response.status!==200)
        {
            throw new Error(response.status)
        }
        return response.json();
      }) 
      .then(json => {return json;}) 
      .catch(err => {
        if(parseInt(err.message) === 401){
          localStorage.clear();
          return redirect("/login")
        }
        return null;
      });
    }
    return redirect("/login")
  }

  async function loadUserDetails( { params } ){
    let login = JSON.parse(localStorage.getItem("login"));
    if(login){
      let myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+login.token);
      const urlUserDetails = `${
        process.env.REACT_APP_API_BASE
        }/user-details/${params.userId}`;

      return fetch(urlUserDetails, {
        method: "GET",
        headers: myHeaders
      })
      .then(response => {
        if(response.status!==200)
        {
            throw new Error(response.status)
        }
        return response.json();
      }) 
      .then(userDetails => {
        let myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer "+login.token);
        const urlVersions = `${
          process.env.REACT_APP_API_BASE
          }/versions`;
        return fetch(urlVersions, {
          method: "GET",
          headers: myHeaders
        })
        .then(response => {
          if(response.status!==200)
          {
              throw new Error(response.status)
          }
          return response.json();
        }) 
        .then(versions => {
          return {userDetails, versions}
        }) 
        .catch(err => {
          if(parseInt(err.message) === 401){
            localStorage.clear();
            return redirect("/login")
          }
          return null;
        });
      }) 
      .catch(err => {
        if(parseInt(err.message) === 401){
          localStorage.clear();
          return redirect("/login")
        }
        return null;
      });
    }
    return redirect("/login")
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard />,
      loader: () => {
        if(!localStorage.getItem("login")){
          return redirect("/login");
        }
        return null;
      },
      children: [

        {
          path: "/",
          element: <Welcome />,
        },
        {
          path: "company-videos",
          element: <CompanyVideos />,
          loader: loadCompanyVideos,
        },
        {
          path: "brand/:brandId",
          element: <Brand />,
          loader: loadBrand,
        },
        {
          path: "brand/:brandId/new-fleet",
          element: <NewEditFleet />,
        },
        {
          path: "brand/:brandId/edit-fleet/:fleetId",
          element: <NewEditFleet />,
          loader: loadFleet,
        },
        {
          path: "brand/:brandId/fleet/:fleetId/new-version",
          element: <NewEditVersion />,
          loader: () => {return {version:null, users:[]}}
        },
        {
          path: "brand/:brandId/fleet/:fleetId/edit-version/:versionId",
          element: <NewEditVersion />,
          loader: loadVersion,
        },
        {
          path: "pending-users",
          element: <Users status="Pending Users" />,
          loader: loadPendingUsers,
        },
        {
          path: "approved-users",
          element: <Users status="Approved Users" />,
          loader: loadApprovedUsers,
        },
        {
          path: "rejected-users",
          element: <Users status="Rejected Users" />,
          loader: loadRejectedUsers,
        },
        {
          path: "user-details/:userId",
          element: <UserDetails />,
          loader: loadUserDetails,
        },
      ],
    },
    {
      path: "/login",
      element: <SignIn />,
      loader: () => {
        if(localStorage.getItem("login")){
          return redirect("/");
        }
        return null;
      }
    },
  ]);

  return (<RouterProvider router={router} />);
}

export default App;
