import React, {useState} from 'react';
import { TextField, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import ButtonGroup from '@mui/material/ButtonGroup';
import ClearIcon from '@mui/icons-material/Clear';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from './ConfirmDialog';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
 
const Media = (props) => {

    const [loading, setLoading] = useState(false);
    const [legend, setLegend] = useState('');
    const [legendToEdit, setLegendToEdit] = useState('');
    const [mood, setMood] = useState('');
    const [moodToEdit, setMoodToEdit] = useState('');
    const [moodSelect, setmoodSelect] = useState(null);
    const [order, setOrder] = useState('1');
    const [orderToEdit, setOrderToEdit] = useState('1');
    const [mediaFiles, setMediaFiles] = useState('');
    const [media, setMedia] = useState(props.media);
    const [mediaToEdit, setMediaToEdit] = useState(null);
    const [mediaToDelete, setMediaToDelete] = useState(null);
    const navigate = useNavigate();
 
    function handleSubmit(event) {
        event.preventDefault();
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
          setLoading(true);
            if(mediaFiles === ''){
                setLoading(false);
                return null;
            }

            const formData = new FormData();

            formData.append("legend", legend);
            formData.append("mood", mood);
            formData.append("order", order);
            if(props.version != null){
                formData.append("version", props.version);
            }
            formData.append("category", props.category);
            if(mediaFiles !== '' && typeof mediaFiles === "object"){
                for(let i=0; i<mediaFiles.length; i++){
                    formData.append("media[]", mediaFiles[i]);
                }
            }
            formData.append("language", 'it-IT');
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);
            const url = `${
                process.env.REACT_APP_API_BASE
                }/add-media`;
        
            fetch(url, {
                method: "POST",
                body: formData,
                headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              }) 
            .then(json => {
                let currentMedia = media;
                for(let i=0; i<json.length; i++){
                    let newMedia = [];
                    let toAdd = json[i];
                    currentMedia.forEach(mediaElement => {
                        if(toAdd && mediaElement.order > toAdd.order){
                            newMedia.push(toAdd);
                            toAdd = false;
                        }
                        newMedia.push(mediaElement);
                    });
                    if(toAdd){
                        newMedia.push(toAdd);
                    }
                    currentMedia = newMedia;
                }
                //setMedia([...media, json]);
                setMedia(currentMedia);
                setOrder('1');
                setLegend('');
                setMood('');
                setMediaFiles('');
                setLoading(false);
                }) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  setLoading(false);
                  navigate("/login")
                }
                setLoading(false);
              });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }

    function editMedia(id) {
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
            const formData = new FormData();

            formData.append("legend", legendToEdit);
            formData.append("mood", moodToEdit);
            formData.append("order", orderToEdit);
            if(props.version != null){
                formData.append("version", props.version);
            }
            formData.append("category", props.category);
            formData.append("language", 'it-IT');
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);
            const url = `${
                process.env.REACT_APP_API_BASE
                }/edit-media/${id}`;
        
            fetch(url, {
                method: "POST",
                body: formData,
                headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
            }) 
            .then(json => {
                let newMedia = [];
                let toAdd = true;
                media.forEach(mediaElement => {
                    if(toAdd && mediaElement.order > json.order){
                        newMedia.push(json);
                        toAdd = false;
                    }
                    if(mediaElement.id !== id){
                        newMedia.push(mediaElement);
                    }
                });
                if(toAdd){
                    newMedia.push(json);
                }
                //setMedia([...media, json]);
                setMedia(newMedia);
                setOrderToEdit('1');
                setLegendToEdit('');
                setMoodToEdit('');
                setMediaToEdit(null);
                }) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                    localStorage.clear();
                    navigate("/login");
                }
            });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }

    function deleteMediaFiles(){
        setMediaFiles('');
    }

    function handleMediaFiles(e){
        setMediaFiles(e.target.files);
    }

    function deleteMedia(id){
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);

            const url = `${
            process.env.REACT_APP_API_BASE
            }/delete-media/${id}`;
      
            fetch(url, {
            method: "GET",
            headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              }) 
            .then(json => {
                let newMedia = media.filter(media => {
                    if(media.id === id){
                        return false;
                    }
                    return true;
                });
                setMedia(newMedia);
            }) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  navigate("/login");
                }
              });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }

    let categoryName = "";
    if(parseInt(props.category) === 5){
        categoryName = "Exterior Design";
    }else if(parseInt(props.category) === 7){
        categoryName = "Interior Design";
    }else if(parseInt(props.category) === 8){
        categoryName = "General Plan";
    }else if(parseInt(props.category) === 9){
        categoryName = "Data Sheet";
    }else if(parseInt(props.category) === 10){
        categoryName = "Brochure";
    }else if(parseInt(props.category) === 11){
        categoryName = "Video";
    }else if(parseInt(props.category) === 12){
        categoryName = "Brand Video";
    }

    let mediaFilesString = '';
    for(let i=0; i<mediaFiles.length; i++){
        mediaFilesString += mediaFiles[i].name +', '
    }
    if(mediaFilesString.length > 0){
        mediaFilesString = mediaFilesString.substring(0, mediaFilesString.length-2);
    }

    let deleteMessage = null;
    if(mediaToDelete != null){
        media.forEach(selected => {
            if(selected.id === mediaToDelete){
                deleteMessage = 'Are you sure to delete "'+selected.original_filename+'"?';
            }
        })
    }

    function loadMoodOptions(){
        let moodOptions = [];
        moodOptions.push(<MenuItem key={null} value={null}>All</MenuItem>);
        let moods = [];
        media.forEach(mediaSelected => {
            let notFound = true;
            moods.forEach(mood => {
                if (mood === mediaSelected.mood) {
                    notFound = false;
                }
            })
            if (notFound) {
                moods.push(mediaSelected.mood)
            }
        });
        for (let i = 0; i < moods.length; i++) {
            if (moods[i] != null) {
                moodOptions.push(<MenuItem key={moods[i]} value={moods[i]}>{moods[i]}</MenuItem>);
            }
        }
        return moodOptions;
    }
    //loadMoodOptions();

    return (
        <React.Fragment>
            <ConfirmDialog message={deleteMessage} confirm={() => deleteMedia(mediaToDelete)} disagree={()=>{setMediaToDelete(null)}} />
            <Box display="flex" style={{alignItems: "center"}}>
                <h2>{categoryName}</h2>
                <FormControl  size="small" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="mood-select-label">Mood</InputLabel>
                    <Select
                    labelId="mood-select-label"
                    id="mood-select"
                    value={moodSelect}
                    label="Age"
                    onChange={(e)=>{setmoodSelect(e.target.value)}}
                    >
                    {loadMoodOptions()}
                    </Select>
                </FormControl>
            </Box>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Legend</TableCell>
                        <TableCell>Mood</TableCell>
                        <TableCell>Order</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {media.filter((row) => {
                        if(moodSelect == null){
                            return true;
                        }else{
                            if(moodSelect === row.mood){
                                return true;
                            }else{
                                return false;
                            }
                        }
                    }).map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row">
                            {row.original_filename}
                        </TableCell>
                        {row.id === mediaToEdit ? 
                            <TableCell>
                                <TextField
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Legend"
                                    onChange={e => setLegendToEdit(e.target.value)}
                                    value={legendToEdit}
                                    fullWidth
                                />
                            </TableCell>
                        :
                            <TableCell>{row.legend}</TableCell>}
                        {row.id === mediaToEdit ? 
                            <TableCell>
                                <TextField
                                    type="text"
                                    variant='outlined'
                                    color='primary'
                                    label="Mood"
                                    onChange={e => setMoodToEdit(e.target.value)}
                                    value={moodToEdit}
                                    fullWidth
                                />
                            </TableCell>
                        :
                            <TableCell>{row.mood}</TableCell>}
                        {row.id === mediaToEdit ? 
                            <TableCell>
                                <TextField
                                    type="number"
                                    variant='outlined'
                                    color='primary'
                                    label="Order"
                                    onChange={e => setOrderToEdit(e.target.value)}
                                    value={orderToEdit}
                                    fullWidth
                                />
                            </TableCell>
                        :
                            <TableCell>{row.order}</TableCell>}
                        <TableCell align="right">
                            {row.id === mediaToEdit ? 
                                <>
                                    <IconButton onClick={()=>{editMedia(row.id)}} aria-label="edit">
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton onClick={()=>{setLegendToEdit(''); setMoodToEdit(''); setOrderToEdit('1'); setMediaToEdit(null)}} aria-label="edit">
                                        <CloseIcon />
                                    </IconButton>
                                </>
                                :
                                <IconButton onClick={()=>{setLegendToEdit(row.legend != null ? row.legend : ''); setMoodToEdit(row.mood != null ? row.mood : ''); setOrderToEdit(row.order); setMediaToEdit(row.id)}} aria-label="edit">
                                    <EditIcon />
                                </IconButton>
                            }
                            <IconButton onClick={()=>{setMediaToDelete(row.id)}} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    color='primary'       
                    type="text"
                    fullWidth
                    required
                    label="Media"
                    sx={{marginTop: 4, mb: 4}}
                    disabled
                    value={mediaFiles ? mediaFilesString : "Select Media"}
                    InputProps={{
                        endAdornment: (
                            <ButtonGroup aria-label="outlined primary button group"><IconButton component="label">
                            <FileUploadOutlined />
                            <input
                            styles={{display:"none"}}
                            type="file"
                            hidden
                            onChange={handleMediaFiles}
                            multiple
                            name="media[]"
                            />
                        </IconButton>
                        <IconButton component="label" onClick={deleteMediaFiles}>
                            <ClearIcon />
                        </IconButton>
                        </ButtonGroup>
                        ),
                    }}
                    />
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Legend"
                        onChange={e => setLegend(e.target.value)}
                        value={legend}
                        fullWidth
                    />
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Mood"
                        onChange={e => setMood(e.target.value)}
                        value={mood}
                        fullWidth
                    />
                    <TextField
                        type="number"
                        variant='outlined'
                        color='primary'
                        label="Order"
                        onChange={e => setOrder(e.target.value)}
                        value={order}
                        fullWidth
                        required
                    />
                </Stack>
                <LoadingButton
                    type="submit"
                    loading = {loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="outlined"
                >
                    Save
                </LoadingButton>
                {/*<Button variant="outlined" color="secondary" type="submit">Save</Button>*/}
            </form>
     
        </React.Fragment>
    )
}
 
export default Media;