import React, {useState} from 'react';
import { TextField, Button, Stack } from '@mui/material';
import { Link,useParams,useNavigate, useLoaderData } from "react-router-dom";
 
const NewEditFleet = (props) => {
    const fleet = useLoaderData();

    const [name, setName] = useState(fleet != null ? fleet.name : '');
    const [order, setOrder] = useState(fleet != null ? fleet.order : '1');
    const navigate = useNavigate();
    let { brandId, fleetId } = useParams();

    function handleSubmit(event) {
        event.preventDefault();
        
        let login = JSON.parse(localStorage.getItem("login"));
        if(login){
            const formData = new FormData();

            formData.append("name", name);
            formData.append("order", order);
            formData.append("brand", brandId);
            
            let myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer "+login.token);
            let url = null;
            if(fleetId != null){
                url = `${
                    process.env.REACT_APP_API_BASE
                    }/save-fleet/${fleetId}`;
            }else{
                url = `${
                    process.env.REACT_APP_API_BASE
                    }/save-fleet`;
            }
        
            fetch(url, {
                method: "POST",
                body: formData,
                headers: myHeaders
            })
            .then(response => {
                if(response.status!==200)
                {
                    throw new Error(response.status)
                }
                return response.json();
              }) 
            .then(json => {navigate("/brand/"+brandId)}) 
            .catch(err => {
                if(parseInt(err.message) === 401){
                  localStorage.clear();
                  navigate("/login");
                }
              });
        }else{
            localStorage.clear();
            navigate("/login");
        }
    }
 
    return (
        <React.Fragment>
            <h2>Fleet</h2>
            <form onSubmit={handleSubmit} action={<Link to="/login" />}>
                <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                    <TextField
                        type="text"
                        variant='outlined'
                        color='primary'
                        label="Name"
                        onChange={e => setName(e.target.value)}
                        value={name}
                        fullWidth
                        required
                    />
                    <TextField
                        type="number"
                        variant='outlined'
                        color='primary'
                        label="Order"
                        onChange={e => setOrder(e.target.value)}
                        value={order}
                        fullWidth
                        required
                    />
                </Stack>
                <Stack spacing={1} direction="row">
                    <Button variant="outlined" color="secondary"><Link to={"/brand/"+brandId}>Back</Link></Button>
                    <Button variant="outlined" color="primary" type="submit">Save</Button>
                </Stack>
            </form>
     
        </React.Fragment>
    )
}
 
export default NewEditFleet;